import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Components/Login'; // Adjust the path to the components folder
import Rendermap from './Components/Rendermap'; // Adjust the path to the components folder
import ActivitiesEdit from './Components/ActivitiesEdit';
import Select_layers from './Components/Select_layers_edit';
import Festival_edit from './Components/Festival_edit';
import Disableperson_edit from './Components/Disableperson_edit';
import AddForm from './Components/AddForm';
import AddFormfestival from './Components/AddForm_festival';
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/rendermap" element={<Rendermap />} />
        <Route path="/Edit" element={<Select_layers />} />
        <Route path="/Festival_edit" element={<Festival_edit />} />
        <Route path="/AddForm" element={<AddForm />} />
        <Route path="/ActivitiesEdit" element={<ActivitiesEdit />} />
        <Route path="/Disableperson_edit" element={<Disableperson_edit />} />
        <Route path="/AddFormfestival" element={<AddFormfestival />} />
        <Route 
          path="*" 
          element={<h1>404 - Page Not Found</h1>} 
        />
      </Routes>
    </Router>
  );
}

export default App;
