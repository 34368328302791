import React from "react";
import { useNavigate } from 'react-router-dom';
import { BiArrowBack } from "react-icons/bi";

export default function Select_layers() {
    const navigate = useNavigate();

    const handlegotomarkerfestival = () => {
        navigate('/Festival_edit');
    };

    const handlegotomarkerdisableperson = () => {
        navigate('/Disableperson_edit');
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
            {/* ปุ่มกลับ */}
            <button
                onClick={handleBack}
                className="absolute top-4 left-4 p-2 flex items-center text-gray-600 hover:text-gray-900 transition-colors"
            >
                <BiArrowBack size={24} className="mr-1" />
                กลับ
            </button>

            {/* Card */}
            <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-md">
                <div className="text-2xl font-bold text-center mb-8 text-gray-800">
                    เลือกชั้นข้อมูล
                </div>
                
                <div className="space-y-4">
                    <button
                        type="button"
                        // disabled
                        onClick={handlegotomarkerfestival}
                        className="w-full py-3 px-6 text-sm font-medium text-gray-900 bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 transition-colors duration-300 ease-in-out shadow-sm"
                        //   className="w-full py-3 px-6 text-sm font-medium text-gray-500 bg-gray-200 rounded-full border border-gray-300 cursor-not-allowed"
                    >
                        ตำแหน่งงานเทศกาล
                    </button>

                    <button
                        onClick={handlegotomarkerdisableperson}
                        type="button"
                        className="w-full py-3 px-6 text-sm font-medium text-gray-900 bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 transition-colors duration-300 ease-in-out shadow-sm"
                    >
                        ตำแหน่งคนพิการ
                    </button>
                </div>
            </div>
        </div>
    );
}