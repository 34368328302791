// src/activitiesService.js

const API_KEY = 'Chaipong';

export const fetchActivities = async () => {
    try {
        const response = await fetch("http://localhost/crud_lastest_version2/crud_lastest_version2/backend/activities_markers.php", {
            // https://www.chaipongmap.com/activities_markers.php
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': API_KEY
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch Activities');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching activities:', error);
        throw error;
    }
};

export const readActivities = async () => {
    try {
        const response = await fetch("http://localhost/crud_lastest_version2/crud_lastest_version2/backend/read_activities.php", {
            // "https://www.chaipongmap.com/read_activities.php"
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': API_KEY
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch Activities');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching activities:', error);
        throw error;
    }
};

export const createActivities = async (newActivities) => {
    try {
        const response = await fetch('http://localhost/crud_lastest_version2/crud_lastest_version2/backend/create_activities.php', {
            // https://www.chaipongmap.com/create_activities.php
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': API_KEY
            },
            body: JSON.stringify(newActivities),
        });

        if (!response.ok) {
            throw new Error('Failed to create Activities');
        }
        return await response.json();
    } catch (error) {
        console.error('Error creating Activities:', error);
        throw error;
    }
};

export const updateActivities = async (editableActivities) => {
    const { id, location_id, activity_date, activity } = editableActivities;

    if (!id || !location_id || !activity_date || !activity) {
        throw new Error('All fields are required to update activities.');
    }

    const endpoint = 'http://localhost/crud_lastest_version2/crud_lastest_version2/backend/update_activities.php';
    // https://www.chaipongmap.com/update_activities.php

    try {
        const response = await fetch(endpoint, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': API_KEY
            },
            body: JSON.stringify({
                id,
                location_id,
                activity_date,
                activity,
            }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Failed to update activity: ${errorData.error || 'Unknown error'}`);
        }

        const data = await response.json();

        if (data.message) {
            return data;
        } else {
            throw new Error('Unexpected response format');
        }
    } catch (error) {
        console.error('Error updating activity:', error);
        throw error;
    }
};

export const deleteActivities = async (id) => {
    try {
        console.log("Deleting Activities with ID:", id);
        const response = await fetch('http://localhost/crud_lastest_version2/crud_lastest_version2/backend/delete_activities.php', {
            // https://www.chaipongmap.com/delete_activities.php
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': API_KEY
            },
            body: JSON.stringify({ ID: id })
        });

        const data = await response.json();

        console.log("Response from delete Activities:", data);

        if (data.status === 'error') {
            console.error('Error deleting Activities:', data.message);
            throw new Error(data.message);
        }

        return data;
    } catch (error) {
        console.error('Error deleting Activities:', error);
        throw error;
    }
};
