import React, { useEffect, useState, useCallback } from 'react';
import { Marker, Popup, useMapEvents } from 'react-leaflet';
import { Icon } from 'leaflet';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

const customIcon = new Icon({
    iconUrl: "https://cdn.pixabay.com/photo/2015/05/09/02/51/handicap-759184_1280.png",
    iconSize: [38, 38],
});

const MapMarkersdisableperson = ({ onEdit, onMarkerChange }) => {
    const [markers, setMarkers] = useState([]);
    const location = useLocation();
    const isDisablepersonEdit = location.pathname.includes('Disableperson_edit');

    // Fetch markers from the backend
    const fetchMarkers = useCallback(async () => {
        try {
            const response = await fetch(
                // 'http://localhost/crud_lastest_version2/crud_lastest_version2/backend/read_markers_disable_person.php', 
                'https://www.chaipongmap.com/read_markers_disable_person.php',
                {
                    method: 'GET',
                    headers: {
                        'X-API-KEY': 'Chaipong',
                        'Content-Type': 'application/json'
                    }
                }
            );

            const data = await response.json();
            if (data.status === "success") {
                setMarkers(data.data);
            } else {
                console.error('Error:', data.message);
            }
        } catch (error) {
            console.error('Error fetching markers:', error);
        }
    }, []);

    // เพิ่มข้อมูลใหม่
    const handleAddMarker = useCallback(async (newMarker) => {
        try {
            const response = await fetch(
                // 'http://localhost/crud_lastest_version2/crud_lastest_version2/backend/create_markers_disable_person.php',
                'https://www.chaipongmap.com/create_markers_disable_person.php',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(newMarker),
                }
            );
            const result = await response.json();
            if (result.status === 'success') {
                setMarkers(prevMarkers => [...prevMarkers, { ...newMarker, id: result.id }]);
                return true;
            } else {
                console.error('Add failed:', result.message);
                alert('ไม่สามารถเพิ่มข้อมูลได้: ' + result.message);
                return false;
            }
        } catch (error) {
            console.error('Error:', error);
            alert('เกิดข้อผิดพลาดในการเพิ่มข้อมูล');
            return false;
        }
    }, []);

    // อัพเดทข้อมูล
    const handleUpdateMarker = useCallback(async (updatedMarker) => {

        console.log('Original updatedMarker:', updatedMarker);

        if (!updatedMarker || !updatedMarker.id) {
            console.error('Invalid marker data');
            return false;
        }
    
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 5000);
        
        // เพิ่มการ log ข้อมูลก่อนแปลงเป็น JSON
        console.log('Data being sent:', updatedMarker);
        console.log('JSON string:', JSON.stringify(updatedMarker));
    
        try {
            const response = await fetch(
                // 'http://localhost/crud_lastest_version2/crud_lastest_version2/backend/update_disable_person.php',
                'https://www.chaipongmap.com/update_disable_person.php',
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json' // เพิ่ม header นี้
                    },
                    body: JSON.stringify(updatedMarker),
                    signal: controller.signal
                }
            );
            
            clearTimeout(timeoutId);

            // เพิ่มการ log response
            console.log('Response status:', response.status);
            console.log('Response headers:', response.headers);
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const result = await response.json();
            // เพิ่มการ log ผลลัพธ์
            console.log('Response result:', result);

            if (result.status === 'success') {
                setMarkers(prevMarkers =>
                    prevMarkers.map(marker =>
                        marker.id === updatedMarker.id ? updatedMarker : marker
                    )
                );
                return true;
            } else {
                console.error('Update failed:', result.message);
                alert('ไม่สามารถอัพเดทข้อมูลได้: ' + result.message);
                return false;
            }
        } catch (error) {
            console.error('Error:', error);
            console.error('Error details:', error.message); // เพิ่มรายละเอียด error
            alert('เกิดข้อผิดพลาดในการอัพเดทข้อมูล');
            return false;
        }
    }, []);

    // ลบข้อมูล
    const handleDeleteMarker = useCallback(async (markerId) => {
        if (window.confirm('คุณต้องการลบข้อมูลนี้ใช่หรือไม่?')) {
            try {
                const response = await fetch(
                    // 'http://localhost/crud_lastest_version2/crud_lastest_version2/backend/delete_markers_disable_person.php',
                    'https://www.chaipongmap.com/delete_markers_disable_person.php',
                    {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ id: markerId }),
                    }
                );
                const result = await response.json();
                if (result.status === 'success') {
                    setMarkers(prevMarkers => prevMarkers.filter(marker => marker.id !== markerId));
                    return true;
                } else {
                    console.error('Delete failed:', result.message);
                    alert('ไม่สามารถลบข้อมูลได้: ' + result.message);
                    return false;
                }
            } catch (error) {
                console.error('Error:', error);
                alert('เกิดข้อผิดพลาดในการลบข้อมูล');
                return false;
            }
        }
    }, []);

    useEffect(() => {
        if (onMarkerChange) {
            onMarkerChange({
                addMarker: handleAddMarker,
                updateMarker: handleUpdateMarker,
                deleteMarker: handleDeleteMarker
            });
        }
    }, [onMarkerChange, handleAddMarker, handleUpdateMarker, handleDeleteMarker]);

    useEffect(() => {
        fetchMarkers();
    }, [fetchMarkers]);

    const map = useMapEvents({
        click: () => {
            map.closePopup();
        }
    });

    return (
        <>
            {markers.map((marker) => (
                <Marker
                    key={marker.id}
                    position={[parseFloat(marker.latitude), parseFloat(marker.longitude)]}
                    icon={customIcon}
                >
                    <Popup>
                        <div className="marker-popup-content">
                            <p><strong>หมู่ :</strong> {marker.villagenumber}</p>
                            <p><strong>บ้านเลขที่ :</strong> {marker.housenumber}</p>
                            <p><strong>ตำบล:</strong> {marker.tambon}</p>
                            <p><strong>อำเภอ:</strong> {marker.amphoe}</p>
                            <p><strong>จังหวัด:</strong> {marker.province}</p>
                            <p><strong>ชื่อ:</strong> {marker.prefix} {marker.firstname} {marker.lastname}</p>
                            <p><strong>ลักษณะความพิการ:</strong> {marker.typeofdisability}</p>
                            <p><strong>อายุ:</strong> {marker.age}</p>
                            <p><strong>หมายเหตุ:</strong> {marker.remarks || "ไม่ระบุ"}</p>
                            
                            {isDisablepersonEdit && (
                                <div className="flex justify-end space-x-2 mt-2">
                                    <button
                                        onClick={() => onEdit(marker)}
                                        className="p-2 text-blue-600 hover:text-blue-800"
                                        title="แก้ไข"
                                    >
                                        <FaEdit size={16} />
                                    </button>
                                    <button
                                        onClick={() => handleDeleteMarker(marker.id)}
                                        className="p-2 text-red-600 hover:text-red-800"
                                        title="ลบ"
                                    >
                                        <FaTrash size={16} />
                                    </button>
                                </div>
                            )}
                        </div>
                    </Popup>
                </Marker>
            ))}
        </>
    );
};

export default MapMarkersdisableperson;