import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Marker, Popup, useMapEvents } from 'react-leaflet';
import { Icon } from 'leaflet';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

const customIcon = new Icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/512/9131/9131546.png",
    iconSize: [38, 38]
});

const MapMarkersfestival = ({ onEdit, onMarkerChange }) => {
    const [markers, setMarkers] = useState([]);
    const location = useLocation();
    const isDisablepersonEdit = location.pathname.includes('Festival_edit');
    const API_KEY = 'Chaipong';

    // Create all handler functions
    // Helper function to safely parse coordinates
    const safeParseFloat = (value, defaultValue = 0) => {
        const parsed = parseFloat(value);
        return (!isNaN(parsed) && isFinite(parsed)) ? parsed : defaultValue;
    };

    // Helper function to validate coordinates
    const isValidCoordinate = (lat, lng) => {
        return !isNaN(lat) && !isNaN(lng) && 
               lat >= -90 && lat <= 90 && 
               lng >= -180 && lng <= 180;
    };
    const fetchactivities_markers = useCallback(async () => {
        try {
            const response = await fetch(
                // 'http://localhost/crud_lastest_version2/crud_lastest_version2/backend/activities_markers.php',
                'https://www.chaipongmap.com/activities_markers.php',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-API-KEY': API_KEY
                    }
                }
            );
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const responseData = await response.json();
            console.log('Raw activities data:', responseData);
            
            // ตรวจสอบว่าเป็น response แบบใหม่ที่มี status และ data
            const activitiesData = responseData.data || responseData;
            
            if (!activitiesData || !Array.isArray(activitiesData)) {
                console.error('Invalid data format received:', responseData);
                return;
            }
    
            const processedMarkers = activitiesData
                .filter(location => {
                    if (!location) return false;
                    const latitude = safeParseFloat(location.latitude);
                    const longitude = safeParseFloat(location.longitude);
                    return isValidCoordinate(latitude, longitude);
                })
                .map(location => {
                    const latitude = safeParseFloat(location.latitude);
                    const longitude = safeParseFloat(location.longitude);
                    
                    // ตรวจสอบให้แน่ใจว่ามีข้อมูลที่จำเป็นครบถ้วน
                    if (!location.location_id || !location.Location) {
                        console.warn('Missing required data for location:', location);
                        return null;
                    }
    
                    return {
                        location_id: location.location_id,
                        Location: location.Location,
                        address: location.address || '',
                        latitude: latitude.toFixed(6),
                        longitude: longitude.toFixed(6),
                        activities: Array.isArray(location.activities) ? location.activities.map(activity => ({
                            id: activity.id,
                            date: activity.date || '',
                            activities: activity.activities || '',
                            reference: activity.reference || ''
                        })) : []
                    };
                })
                .filter(marker => marker !== null); // กรองออกถ้ามี null
    
            console.log('Processed Markers:', processedMarkers);
            
            if (processedMarkers.length === 0) {
                console.warn('No valid markers found after processing');
            }
    
            setMarkers(processedMarkers);
    
        } catch (error) {
            console.error('Error fetching markers:', error);
            // ไม่ต้อง setMarkers เป็น array ว่างเมื่อเกิด error
            // setMarkers([]);
        }
    }, [API_KEY]);

    const handleAddMarker = useCallback(async (newMarker) => {
        try {
            // Log ข้อมูลก่อนส่ง
            console.log('Preparing to send marker data:', newMarker);
            
            // http://localhost/crud_lastest_version2/crud_lastest_version2/backend/create_marker.php

            const response = await fetch('https://www.chaipongmap.com/create_marker.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY': API_KEY,
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    location_name: newMarker.Location,
                    address: newMarker.address,
                    latitude: newMarker.latitude,
                    longitude: newMarker.longitude
                })
            });
    
            // Log response
            console.log('Response status:', response.status);
            
            const responseData = await response.json();
       
            console.log('Server response:', responseData);
    
            if (!response.ok) {
                throw new Error(responseData.message || 'Failed to create marker');
            }
            await fetchactivities_markers();
            return responseData;
        } catch (error) {
            console.error('Full error details:', error);
            throw error;
        }
    }, [API_KEY, fetchactivities_markers]);

    const fetchMarkers = useCallback(async () => {
        // "http://localhost/crud_lastest_version2/crud_lastest_version2/backend/read_markers.php"
        try {
            const response = await fetch("https://www.chaipongmap.com/read_markers.php", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY': API_KEY
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch markers');
            }
            return await response.json();
        } catch (error) {
            console.error('Error fetching markers:', error);
            throw error;
        }
    }, [API_KEY]);

    const handleEditMarker = useCallback(async (editableMarker) => {
        // 'http://localhost/crud_lastest_version2/crud_lastest_version2/backend/update_marker.php'
        try {
            console.log("Editing marker with data:", editableMarker);
            
            const response = await fetch('https://www.chaipongmap.com/update_marker.php', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY': API_KEY
                },
                body: JSON.stringify(editableMarker),
            });
    
            // Parse the response
            const result = await response.json();
            console.log("Edit marker response:", result);
    
            // Check for successful response
            if (response.ok && result.status === 'success') {
                // Refresh markers after successful edit
                await fetchactivities_markers();
                return result;
            } else {
                // Handle error cases
                console.error("Error updating marker:", result);
                throw new Error(result.message || 'Failed to update marker');
            }
        } catch (error) {
            console.error('Error updating marker:', error);
            throw error;
        }
    }, [API_KEY, fetchactivities_markers]);

    // ใน MapMarkersfestival.jsx

const handleDeleteMarker = useCallback(async (locationId) => {
    if (!window.confirm('คุณต้องการลบสถานที่นี้ใช่หรือไม่?')) {
        return false;
    }
    // 'http://localhost/crud_lastest_version2/crud_lastest_version2/backend/delete_marker.php'
    try {
        console.log("Deleting marker with ID:", locationId);
        
        const response = await fetch('https://www.chaipongmap.com/delete_marker.php', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': API_KEY
            },
            body: JSON.stringify({ 
                id: locationId.toString() // ส่งเป็น string เหมือนที่ทำงานใน Postman
            })
        });

        const data = await response.json();
        console.log("Response from delete marker:", data);
        
        if (data.status === 'error') {
            throw new Error(data.message || 'Failed to delete marker');
        }

        await fetchactivities_markers();
        alert('ลบสถานที่สำเร็จ');
        return true;

    } catch (error) {
        console.error('Error deleting marker:', error);
        alert('เกิดข้อผิดพลาดในการลบสถานที่: ' + error.message);
        return false;
    }
}, [API_KEY, fetchactivities_markers]);

const createActivities = useCallback(async (newActivities) => {
    // 'http://localhost/crud_lastest_version2/crud_lastest_version2/backend/create_activities.php'
    try {
        console.log('Preparing to send marker data:', newActivities);
        
        const response = await fetch('https://www.chaipongmap.com/create_activities.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': API_KEY
            },
            body: JSON.stringify(newActivities),
        });

        console.log('Response status:', response.status);
        
        if (!response.ok) {
            const responseText = await response.text();
            console.error('Failed Response:', responseText);
            throw new Error('Failed to create Activities');
        }

        const data = await response.json();
        console.log('Response data:', data);
        await fetchactivities_markers();
        return data;
    } catch (error) {
        console.error('Error creating Activities:', error);
        throw error;
    }
    }, [API_KEY], fetchactivities_markers);

    // 'http://localhost/crud_lastest_version2/crud_lastest_version2/backend/read_activities.php'
    const fetchActivities = useCallback(async () => {
        try {
            const response = await fetch(
                'https://www.chaipongmap.com/read_activities.php',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-API-KEY': API_KEY
                    }
                }
            );
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const responseData = await response.json();
            
            // ตรวจสอบว่าเป็น response แบบใหม่ที่มี status และ data
            const activitiesData = responseData.data || responseData;
            
            if (!Array.isArray(activitiesData)) {
                throw new Error('Invalid data format received');
            }
    
            // สร้าง Map เพื่อรวบรวมกิจกรรมตาม location_id
            const locationActivitiesMap = new Map();
    
            activitiesData.forEach(activity => {
                if (!locationActivitiesMap.has(activity.location_id)) {
                    locationActivitiesMap.set(activity.location_id, {
                        location_id: activity.location_id,
                        Location: '',
                        address: '',
                        latitude: '',
                        longitude: '',
                        activities: []
                    });
                }
    
                const locationData = locationActivitiesMap.get(activity.location_id);
                locationData.activities.push({
                    id: activity.id,
                    date: activity.activity_date,
                    activities: activity.activity,
                    reference: activity.reference || ''
                });
            });
    
            const processedMarkers = Array.from(locationActivitiesMap.values());
            console.log('Processed Markers:', processedMarkers);
            setMarkers(processedMarkers);
    
        } catch (error) {
            console.error('Error fetching markers:', error);
            setMarkers([]);
        }
    }, [API_KEY]);

    const handleEditActivity = useCallback(async (editableActivity) => {
        // http://localhost/crud_lastest_version2/crud_lastest_version2/backend/update_activities.php
        try {
            console.log("Editing activity with data:", editableActivity);
            
            const response = await fetch('https://www.chaipongmap.com/update_activities.php', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY': API_KEY,
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    id: editableActivity.id,
                    location_id: editableActivity.location_id,
                    date: editableActivity.date,
                    activities: editableActivity.activities,
                    reference: editableActivity.reference || null
                }),
            });
    
            const result = await response.json();
            console.log("Edit activity response:", result);
            await fetchActivities();
            // แก้ไขเงื่อนไขการตรวจสอบ
            if (response.ok) {
                // รีเฟรชข้อมูลหลังจากอัพเดทสำเร็จ
                
                return { status: 'success', message: 'อัพเดทข้อมูลสำเร็จ' };
            } else {
                throw new Error(result.message || 'Failed to update activity');
            }
            
        } catch (error) {
            console.error('Error updating activity:', error);
            throw error;
        }
    }, [API_KEY, fetchActivities]);

    const handleDeleteActivity = useCallback(async (id) => {
        // 'http://localhost/crud_lastest_version2/crud_lastest_version2/backend/delete_activities.php'
        if (!window.confirm('คุณต้องการลบกิจกรรมนี้ใช่หรือไม่?')) {
            return false;
        }
        
        try {
            console.log("Deleting activity with ID:", id);
            
            const response = await fetch('https://www.chaipongmap.com/delete_activities.php', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY': API_KEY
                },
                body: JSON.stringify({ id: id.toString() })
            });
    
            const data = await response.json();
            console.log("Delete response:", data);
    
            if (data.status === 'ok') { // เปลี่ยนจาก 'error' เป็น 'ok'
                await fetchactivities_markers();
                alert('ลบกิจกรรมสำเร็จ');
                return true;
            } else {
                throw new Error(data.message || 'Failed to delete activity');
            }
        } catch (error) {
            console.error('Error deleting activity:', error);
            alert('เกิดข้อผิดพลาดในการลบกิจกรรม: ' + error.message);
            return false;
        }
    }, [API_KEY, fetchactivities_markers]);
    // Create ref to store handlers
    const handlersRef = useRef({
        addMarker: handleAddMarker,
        fetchMarkers: fetchMarkers,
        editMarker: handleEditMarker,
        deleteMarker: handleDeleteMarker,
        createActivities: createActivities,
        fetchActivities: fetchActivities,
        editActivity: handleEditActivity,
        deleteActivity: handleDeleteActivity,
    });

    // Update ref when handlers change
    useEffect(() => {
        handlersRef.current = {
            addMarker: handleAddMarker,
            fetchMarkers: fetchMarkers,
            editMarker: handleEditMarker,
            deleteMarker: handleDeleteMarker,
            createActivities: createActivities,
            fetchActivities: fetchActivities,
            editActivity: handleEditActivity,
            deleteActivity: handleDeleteActivity,
            fetchactivities_markers: fetchactivities_markers  // เพิ่มบรรทัดนี้
        };
    }, [
        handleAddMarker,
        handleDeleteMarker,
        handleEditMarker,
        handleEditActivity,
        handleDeleteActivity,
        fetchMarkers,
        createActivities,
        fetchActivities,
        fetchactivities_markers  // เพิ่มบรรทัดนี้
    ]);

    // Pass handlers to parent only when onMarkerChange changes
    useEffect(() => {
        if (onMarkerChange) {
            onMarkerChange(handlersRef.current);
        }
    }, [onMarkerChange]);

    // Fetch initial data
    useEffect(() => {
        fetchactivities_markers();
    }, [fetchactivities_markers]);

    useEffect(() => {
        fetchActivities();
    }, [fetchActivities]);

    useEffect(() => {
        console.log('Markers state updated:', markers);
    }, [markers]);
    
    const map = useMapEvents({
        click: () => {
            map.closePopup();
        }
    });

    const openGoogleStreetView = useCallback((latitude, longitude) => {
        const streetViewUrl = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${latitude},${longitude}`;
        window.open(streetViewUrl, '_blank');
    }, []);

    return (
        <>
            {markers.filter(marker => {
                // Additional filter to ensure valid coordinates at rendering
                const lat = parseFloat(marker.latitude);
                const lng = parseFloat(marker.longitude);
                return isValidCoordinate(lat, lng);
            }).map((marker, index) => (
                <Marker
                    key={`${marker.location_id}-${marker.Location}-${marker.latitude}-${marker.longitude}-${index}`}
                    position={[
                        parseFloat(marker.latitude), 
                        parseFloat(marker.longitude)
                    ]}
                    icon={customIcon}
                >
                    <Popup>
                        <div className="marker-popup-content">
                            <h2>สถานที่ : {marker.Location}</h2>
                            <p>ตำแหน่ง : {marker.address}</p>

                            <table className="w-full mt-2 border-collapse">
                                <thead>
                                    <tr>
                                        <th className="p-2 border">วันและเวลา</th>
                                        <th className="p-2 border">กิจกรรม</th>
                                        <th className="p-2 border">แหล่งอ้างอิง</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {marker.activities && marker.activities.map((activity, i) => (
                                        <tr key={`${activity.date}-${activity.activities}-${i}`}>
                                            <td className="p-2 border">{activity.date}</td>
                                            <td className="p-2 border">{activity.activities}</td>
                                            <td className="p-2 border">
                                                {activity.reference ? (
                                                    <a
                                                        href={activity.reference}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="text-blue-600 hover:text-blue-800 underline"
                                                    >
                                                        คลิกที่นี่
                                                    </a>
                                                ) : (
                                                    <span className="text-gray-400">ไม่ระบุ</span>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="mt-4 text-center">
                                <button
                                    onClick={() => openGoogleStreetView(
                                        parseFloat(marker.latitude),
                                        parseFloat(marker.longitude)
                                    )}
                                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                                >
                                    เปิด Google Street View
                                </button>

                                {isDisablepersonEdit && (
                                    <div className="flex justify-end space-x-2 mt-2">
                                        <button
                                            onClick={() => onEdit({
                                                location_id: marker.location_id,
                                                Location: marker.Location,
                                                address: marker.address,
                                                latitude: marker.latitude,
                                                longitude: marker.longitude,
                                                activities: marker.activities
                                            })}
                                            className="p-2 text-blue-600 hover:text-blue-800"
                                            title="แก้ไข"
                                        >
                                            <FaEdit size={16} />
                                        </button>
                                        <button
                                            onClick={() => handleDeleteMarker(marker.location_id)}
                                            className="p-2 text-red-600 hover:text-red-800"
                                            title="ลบ"
                                        >
                                            <FaTrash size={16} />
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Popup>
                </Marker>
            ))}
        </>
    );
};

export default MapMarkersfestival;