// AddForm.jsx
import React, { useState, useEffect } from 'react';
import { IoMdClose } from 'react-icons/io';
import { useNavigate } from 'react-router-dom'; 

const AddForm = ({ 
    onSubmit, 
    latitude, 
    longitude, 
    onInputChange, 
    onClose, 
    editMode = false, 
    initialData = null 
}) => {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [formData, setFormData] = useState(null);
    const [latitudeValue, setLatitudeValue] = useState(latitude);
    const [longitudeValue, setLongitudeValue] = useState(longitude);

    // Initialize form with initial data when in edit mode
    useEffect(() => {
        if (editMode && initialData) {
            // Populate form with existing data
            const form = document.querySelector('form');
            if (form) {
                form.villagenumber.value = initialData.villagenumber || '';
                form.housenumber.value = initialData.housenumber || '';
                form.tambon.value = initialData.tambon || '';
                form.amphoe.value = initialData.amphoe || '';
                form.province.value = initialData.province || '';
                form.prefix.value = initialData.prefix || '';
                form.firstname.value = initialData.firstname || '';
                form.lastname.value = initialData.lastname || '';
                form.typeofdisability.value = initialData.typeofdisability || '';
                form.age.value = initialData.age || '';
                form.remarks.value = initialData.remarks || '';
            }
            setLatitudeValue(initialData.latitude || latitude);
            setLongitudeValue(initialData.longitude || longitude);
        }
    }, [editMode, initialData, latitude, longitude]);

    // Update latitude and longitude when props change
    useEffect(() => {
        setLatitudeValue(latitude);
        setLongitudeValue(longitude);
    }, [latitude, longitude]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        const data = {
            villagenumber: form.villagenumber.value,
            housenumber: form.housenumber.value,
            tambon: form.tambon.value,
            amphoe: form.amphoe.value,
            province: form.province.value,
            prefix: form.prefix.value,
            firstname: form.firstname.value,
            lastname: form.lastname.value,
            typeofdisability: form.typeofdisability.value,
            age: form.age.value,
            remarks: form.remarks.value,
            latitude: latitudeValue,
            longitude: longitudeValue,
            // If in edit mode, include the original ID
            ...(editMode && initialData && { id: initialData.id }),
        };
        setFormData(data);
        setShowConfirmDialog(true);
    };

    const handleConfirm = () => {
        onSubmit(formData);
        setShowConfirmDialog(false);
    };

    const handleCancel = () => {
        setShowConfirmDialog(false);
    };

    const handleEditCancel = () => {
        if (window.confirm('คุณต้องการยกเลิกการแก้ไขใช่หรือไม่? ข้อมูลที่แก้ไขจะไม่ถูกบันทึก')) {
            onClose();
        }
    };

    const handleLocalInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'latitude') {
          setLatitudeValue(value);
        } else if (name === 'longitude') {
          setLongitudeValue(value);
        }
        onInputChange(e);
    };
    
    const handleReset = () => {
        const form = document.querySelector('form');
        if (form) {
            form.reset();
            setLatitudeValue(latitude);
            setLongitudeValue(longitude);
        }
    };
    const navigate = useNavigate(); 
    const handleBackToMap = () => {
        if (window.confirm('คุณต้องการกลับไปยังแผนที่ใช่หรือไม่? ข้อมูลที่ยังไม่ได้บันทึกจะหายไป')) {
            navigate('/Rendermap'); 
        }
    };
    return (
        <>
            <div className="add-form-container w-full p-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <div className="flex justify-between items-center mb-4">
                    <h5 className="text-xl font-medium text-gray-900 dark:text-white">
                        {editMode ? 'แก้ไขข้อมูล' : 'เพิ่มข้อมูล'}
                    </h5>
          
                    <button
                        onClick={editMode ? handleEditCancel : handleBackToMap}
                        className="flex items-center gap-2 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-white"
                        aria-label="Close"
                    >
                        <IoMdClose size={24} />
                        <span className="text-sm">
                            
                            {editMode ? 'ยกเลิก' : 'กลับไปยังแผนที่'}
                        </span>
                    </button>
                </div>
                
                <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-4 gap-4">
    {/* Row 1 */}
    <div>
        <label htmlFor="villagenumber" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">หมู่ที่</label>
        <input type="text" name="villagenumber" id="villagenumber" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="กรอกหมู่ที่" required />
    </div>

    <div>
        <label htmlFor="housenumber" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">บ้านเลขที่</label>
        <input type="text" name="housenumber" id="housenumber" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="กรอกบ้านเลขที่" required />
    </div>
    <div>
        <label htmlFor="tambon" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">ตำบล</label>
        <input type="text" name="tambon" id="tambon" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="กรอกตำบล" required />
    </div>

    {/* Row 2 */}
    <div>
        <label htmlFor="amphoe" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">อำเภอ</label>
        <input type="text" name="amphoe" id="amphoe" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="กรอกอำเภอ" required />
    </div>
    <div>
        <label htmlFor="province" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">จังหวัด</label>
        <input type="text" name="province" id="province" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="กรอกจังหวัด" required />
    </div>
    <div>
        <label htmlFor="prefix" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">คำนำหน้า</label>
        <input type="text" name="prefix" id="prefix" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="กรอกคำนำหน้า" required />
    </div>
    <div>
        <label htmlFor="firstname" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">ชื่อ</label>
        <input type="text" name="firstname" id="firstname" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="กรอกชื่อ" required />
    </div>

    {/* Row 3 */}
    <div>
        <label htmlFor="lastname" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">นามสกุล</label>
        <input type="text" name="lastname" id="lastname" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="กรอกนามสกุล" required />
    </div>
    <div>
        <label htmlFor="typeofdisability" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">ลักษณะความพิการ</label>
        <input type="text" name="typeofdisability" id="typeofdisability" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="กรอกลักษณะความพิการ" required />
    </div>
    <div>
        <label htmlFor="age" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">อายุ</label>
        <input type="number" name="age" id="age" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="กรอกอายุ" required />
    </div>
    <div>
        <label htmlFor="remarks" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">หมายเหตุ</label>
        <input type="text" name="remarks" id="remarks" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="กรอกหมายเหตุ" />
    </div>

    {/* Row 4 */}
    <div>
        <label htmlFor="latitude" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Latitude</label>
        <input
            type="text"
            name="latitude"
            id="latitude"
            value={latitudeValue}
            onChange={handleLocalInputChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            placeholder="กรอกละติจูด"
            required
        />
    </div>
    <div>
        <label htmlFor="longitude" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Longitude</label>
        <input
            type="text"
            name="longitude"
            id="longitude"
            value={longitudeValue}
            onChange={handleLocalInputChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            placeholder="กรอกลองจิจูด"
            required
        />
    </div>
    <div className="col-span-2 flex space-x-4">
        <button 
            type="submit" 
            className="w-1/2 mt-6 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
            {editMode ? 'บันทึก' : 'สร้างข้อมูล'}
        </button>
        <button 
            type="button"
            onClick={handleReset}
            className="w-1/2 mt-6 text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
        >
            รีเซ็ต
        </button>
    </div>
</div>
                </form>
            </div>

             {/* Confirmation Modal */}
             {showConfirmDialog && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-md w-full mx-4">
                        <h2 className="text-xl font-bold mb-4 text-gray-900 dark:text-white">
                            {editMode ? 'ยืนยันการแก้ไขข้อมูล' : 'ยืนยันการสร้างข้อมูล'}
                        </h2>
                        <div className="text-gray-700 dark:text-gray-300 mb-6 space-y-2">
                            <p className="mb-4">กรุณาตรวจสอบข้อมูลต่อไปนี้:</p>
                            <p>หมู่ที่: {formData?.villagenumber}</p>
                            <p>บ้านเลขที่: {formData?.housenumber}</p>
                            <p>ตำบล: {formData?.tambon}</p>
                            <p>อำเภอ: {formData?.amphoe}</p>
                            <p>จังหวัด: {formData?.province}</p>
                            <p>ชื่อ-นามสกุล: {formData?.prefix}{formData?.firstname} {formData?.lastname}</p>
                            <p>อายุ: {formData?.age} ปี</p>
                            <p>ลักษณะความพิการ: {formData?.typeofdisability}</p>
                            <p>พิกัด: {formData?.latitude}, {formData?.longitude}</p>
                            <p>หมายเหตุ: {formData?.remarks || '-'}</p>
                        </div>
                        <div className="flex justify-end space-x-4">
                            <button
                                onClick={handleCancel}
                                className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600"
                            >
                                ยกเลิก
                            </button>
                            <button
                                onClick={handleConfirm}
                                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-blue-500 dark:hover:bg-blue-400"
                            >
                                ยืนยัน
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AddForm;