//AddForm_festival.jsx
import React, { useState, useEffect } from 'react';
import { IoMdClose } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

const CustomAlert = ({ errors }) => (
  <div className="mb-6 p-4 border-l-4 border-red-500 bg-red-50 dark:bg-red-900/10">
    <div className="font-semibold text-red-700 dark:text-red-400 mb-2">ข้อผิดพลาด</div>
    <ul className="list-disc pl-4 text-red-600 dark:text-red-300">
      {Object.values(errors).map((error, index) => (
        <li key={index}>{error}</li>
      ))}
    </ul>
  </div>
);

const AddFormFestival = ({ 
  onSubmit, 
  latitude = '', 
  longitude = '',  
  onInputChange, 
  onClose, 
  editMode = false, 
  initialData = null,
  onRemoveActivity,
  activities: propActivities,
  setActivities
}) => {
  const navigate = useNavigate();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [formData, setFormData] = useState({
    Location: initialData?.Location || '',
    address: initialData?.address || '',
    latitude: '',
    longitude: ''
  });
  const [formErrors, setFormErrors] = useState({});

  const formFields = [
    { name: 'Location', label: 'ชื่อสถานที่', placeholder: 'กรอกชื่อสถานที่', type: 'text' },
    { name: 'address', label: 'ตำแหน่ง', placeholder: 'กรอกรายละเอียดตำแหน่ง', type: 'text' }
  ];
  useEffect(() => {
    // อัพเดท coordinates เมื่อ latitude หรือ longitude เปลี่ยน
    setFormData(prev => ({
      ...prev,
      latitude: latitude || prev.latitude,
      longitude: longitude || prev.longitude
    }));
  }, [latitude, longitude]);

  useEffect(() => {
    if (editMode && initialData) {
        console.log("Initial edit data:", initialData);
        
        // Explicitly set form data
        setFormData({
            Location: initialData.Location || '',
            address: initialData.address || '',
            latitude: String(initialData.latitude || ''),
            longitude: String(initialData.longitude || '')
        });

        const initialActivities = initialData.activities?.map(activity => ({
            id: activity.id, 
            date: activity.date || '',
            activities: activity.activities || '',
            reference: activity.reference || '',
            location_id: initialData.location_id
        })) || [{ 
            date: '', 
            activities: '', 
            reference: '',
            id: undefined,
            location_id: initialData.location_id 
        }];

        console.log("Mapped activities:", initialActivities);
        setActivities(initialActivities);
    }
}, [editMode, initialData, setActivities]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value || ''
    }));
    onInputChange?.(e);
  };

  const handleAddActivity = () => {
    if (setActivities) {
      setActivities(prev => [...prev, { date: '', activities: '', reference: '' }]);
    }
  };

  const handleRemoveActivityLocal = async (index) => {
    const activity = propActivities[index];
    console.log('Removing activity:', activity);
    
    if (!window.confirm('คุณต้องการลบกิจกรรมนี้ใช่หรือไม่?')) {
        return;
    }

    try {
        if (!activity.id) {
            // กรณีเป็นกิจกรรมใหม่ที่ยังไม่มี id
            setActivities(prev => {
                const newActivities = prev.filter((_, i) => i !== index);
                // ถ้าไม่มีกิจกรรมเหลือ ให้สร้างกิจกรรมว่าง 1 อัน
                return newActivities.length > 0 ? newActivities : [{ 
                    date: '', 
                    activities: '', 
                    reference: ''
                }];
            });
        } else {
            // กรณีเป็นกิจกรรมที่มี id อยู่แล้ว (ข้อมูลในฐานข้อมูล)
            if (await onRemoveActivity(activity.id)) {
                // หลังจากลบสำเร็จจากฐานข้อมูล ค่อยอัพเดท UI
                setActivities(prev => {
                    const newActivities = prev.filter((_, i) => i !== index);
                    return newActivities.length > 0 ? newActivities : [{ 
                        date: '', 
                        activities: '', 
                        reference: ''
                    }];
                });
            }
        }
    } catch (error) {
        console.error('Error removing activity:', error);
        alert('ไม่สามารถลบกิจกรรมได้ กรุณาลองใหม่อีกครั้ง');
    }
};

  const handleActivityChange = (index, field, value) => {
    if (setActivities) {
        setActivities(prev => {
            const newActivities = [...prev];
            newActivities[index] = { 
                ...newActivities[index], 
                [field]: value,
                // เพิ่ม location_id ถ้ามี
                location_id: initialData?.location_id
            };
            return newActivities;
        });
    }
  };

  const validateForm = () => {
    const errors = {};

    formFields.forEach(field => {
      if (!formData[field.name]) {
        errors[field.name] = `กรุณากรอก${field.label}`;
      }
    });

    if (!formData.latitude || !formData.longitude) {
      errors.coordinates = 'กรุณากรอกพิกัดให้ครบถ้วน';
    }

    if (!propActivities || propActivities.length === 0) {
      errors.activities = 'กรุณาเพิ่มอย่างน้อย 1 กิจกรรม';
    } else {
      propActivities.forEach((activity, index) => {
        if (!activity.date) {
          errors[`activity_date_${index}`] = `กรุณากรอกวันและเวลาสำหรับกิจกรรมที่ ${index + 1}`;
        }
        if (!activity.activities) {
          errors[`activity_name_${index}`] = `กรุณากรอกชื่อกิจกรรมที่ ${index + 1}`;
        }
      });
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        return;
    }
  
    // กรองกิจกรรมที่ถูกลบออก
    const nonDeletedActivities = propActivities.filter(activity => !activity.isDeleted);
    
    // Use a Set to track unique activities
    const uniqueActivities = new Set();
    const validActivities = nonDeletedActivities.filter(activity => {
        if (!activity.date.trim() || !activity.activities.trim()) {
            return false;
        }
        const key = `${activity.date.trim()}-${activity.activities.trim()}`;
        if (!uniqueActivities.has(key)) {
            uniqueActivities.add(key);
            return true;
        }
        return false;
    });
  
    if (validActivities.length === 0) {
        alert('กรุณากรอกรายละเอียดกิจกรรมให้ครบถ้วนอย่างน้อย 1 รายการ');
        return;
    }
  
    // Prepare submission data with additional validation
    const submissionData = {
        ...formData,
        latitude: formData.latitude || latitude,
        longitude: formData.longitude || longitude,
        activities: validActivities.map(activity => {
            // ทำความสะอาดข้อมูลก่อนส่ง
            const cleanActivity = {
                date: activity.date.trim(),
                activities: activity.activities.trim(),
                reference: activity.reference ? activity.reference.trim() : '',
                id: activity.id || null
            };

            // ตรวจสอบ URL ของ reference ถ้ามีการกรอก
            if (cleanActivity.reference && !cleanActivity.reference.startsWith('http')) {
                cleanActivity.reference = `https://${cleanActivity.reference}`;
            }

            return cleanActivity;
        })
    };
  
    // Log สำหรับ debug
    console.log('Original activities:', propActivities);
    console.log('Non-deleted activities:', nonDeletedActivities);
    console.log('Valid activities:', validActivities);
    console.log('Submitting form data:', submissionData);

    setFormErrors({});
    setShowConfirmDialog(true);
};

  // In AddForm_festival.jsx
const handleConfirmSubmit = () => {
  const submissionData = {
      ...formData,
      latitude: formData.latitude || latitude,
      longitude: formData.longitude || longitude,
      activities: propActivities.map(activity => ({
          date: activity.date.trim(),
          activities: activity.activities.trim(),
          reference: activity.reference ? activity.reference.trim() : '',
          id: activity.id || null
      }))
  };

  // Call onSubmit with submissionData
  onSubmit(submissionData);
  setShowConfirmDialog(false);
};

  const handleReset = () => {
    setFormData({
      Location: '',
      address: '',
      latitude: latitude || '',
      longitude: longitude || ''
    });
    if (setActivities) {
      setActivities([{ date: '', activities: '', reference: '' }]);
    }
    setFormErrors({});
  };


  const handleBackToMap = () => {
    if (window.confirm('คุณต้องการกลับไปยังแผนที่ใช่หรือไม่? ข้อมูลที่ยังไม่ได้บันทึกจะหายไป')) {
      navigate('/Rendermap');
    }
  };

  const handleEditCancel = () => {
    if (window.confirm('คุณต้องการยกเลิกการแก้ไขใช่หรือไม่? ข้อมูลที่แก้ไขจะไม่ถูกบันทึก')) {
      onClose();
    }
  };
  return (
    <div className="w-full p-6 bg-white rounded-lg shadow-md dark:bg-gray-800">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h5 className="text-xl font-medium text-gray-900 dark:text-white">
          {editMode ? 'แก้ไขข้อมูล' : 'เพิ่มข้อมูล'}
        </h5>
        <button
          onClick={editMode ? handleEditCancel : handleBackToMap}
          className="flex items-center gap-2 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-white"
          aria-label="Close"
        >
          <IoMdClose size={24} />
          <span className="text-sm">
            {editMode ? 'ยกเลิก' : 'กลับไปยังแผนที่'}
          </span>
        </button>
      </div>
  
      {/* Error Alert */}
      {Object.keys(formErrors).length > 0 && <CustomAlert errors={formErrors} />}
  
      {/* Main Form */}
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Location and Coordinates Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {formFields.map(field => (
            <div key={field.name}>
              <label 
                htmlFor={field.name}
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
              >
                {field.label}
              </label>
              <input
                type={field.type}
                name={field.name}
                id={field.name}
                placeholder={field.placeholder}
                value={formData[field.name]}
                onChange={handleInputChange}
                className="w-full px-3 py-2 bg-white dark:bg-gray-700 border rounded-md shadow-sm border-gray-300 dark:border-gray-600 focus:ring-blue-500 focus:border-blue-500 dark:text-white text-sm"
                required
              />
            </div>
          ))}
  
          {/* Coordinates Inputs */}
          {['latitude', 'longitude'].map(coord => (
            <div key={coord}>
              <label 
                htmlFor={coord}
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
              >
                {coord.charAt(0).toUpperCase() + coord.slice(1)}
              </label>
              <input
                type="text"
                name={coord}
                id={coord}
                value={formData[coord]}
                onChange={handleInputChange}
                className="w-full px-3 py-2 bg-white dark:bg-gray-700 border rounded-md shadow-sm border-gray-300 dark:border-gray-600 focus:ring-blue-500 focus:border-blue-500 dark:text-white text-sm"
                required
              />
            </div>
          ))}
        </div>
  
        {/* Activities Section */}
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-medium text-gray-900 dark:text-white">กิจกรรม</h3>
            <button
              type="button"
              onClick={handleAddActivity}
              className="px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 transition-colors"
            >
              เพิ่มกิจกรรม
            </button>
          </div>
  
          {/* Activities List */}
         {propActivities?.filter(activity => !activity.isDeleted).map((activity, index) => (
            <div key={index} className="p-4 border border-gray-200 dark:border-gray-700 rounded-lg space-y-4">
              {/* {console.log("Activity in render:", activity)} */}
              <div className="flex justify-between items-center mb-2">
                <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300">
                  กิจกรรมที่ {index + 1}
                </h4>
                {(propActivities.length > 1 || editMode) && (
                  <button
                    type="button"
                    onClick={() => handleRemoveActivityLocal(index)}
                    className="text-red-600 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300"
                  >
                    ลบ
                  </button>
                )}
            </div>
  
   

 
      {/* แสดง Grid สำหรับ form inputs */}
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                {/* ID Field - แสดงเมื่ออยู่ใน edit mode */}
                {editMode && (
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      รหัสกิจกรรม
                    </label>
                    <input
                      type="text"
                      value={activity.id || '-'}
                      disabled
                      className="w-full px-3 py-2 bg-gray-100 dark:bg-gray-600 border rounded-md shadow-sm border-gray-300 dark:border-gray-600 focus:ring-blue-500 focus:border-blue-500 dark:text-gray-400 text-sm"
                    />
                  </div>
                )}

                {/* Date Input */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    วันและเวลา
                  </label>
                  <input
                    type="text"
                    value={activity.date}
                    onChange={(e) => handleActivityChange(index, 'date', e.target.value)}
                    className="w-full px-3 py-2 bg-white dark:bg-gray-700 border rounded-md shadow-sm border-gray-300 dark:border-gray-600 focus:ring-blue-500 focus:border-blue-500 dark:text-white text-sm"
                    required
                  />
                </div>

                {/* Activity Input */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    กิจกรรม
                  </label>
                  <input
                    type="text"
                    value={activity.activities}
                    onChange={(e) => handleActivityChange(index, 'activities', e.target.value)}
                    className="w-full px-3 py-2 bg-white dark:bg-gray-700 border rounded-md shadow-sm border-gray-300 dark:border-gray-600 focus:ring-blue-500 focus:border-blue-500 dark:text-white text-sm"
                    required
                  />
                </div>

                {/* Reference Input */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    แหล่งอ้างอิง
                  </label>
                  <input
                    type="url"
                    value={activity.reference}
                    onChange={(e) => handleActivityChange(index, 'reference', e.target.value)}
                    className="w-full px-3 py-2 bg-white dark:bg-gray-700 border rounded-md shadow-sm border-gray-300 dark:border-gray-600 focus:ring-blue-500 focus:border-blue-500 dark:text-white text-sm"
                    placeholder="https://example.com"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
  
        {/* Form Actions */}
        <div className="flex gap-4 mt-6">
          <button
            type="submit"
  
            className="flex-1 bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
          >
            {editMode ? 'บันทึก' : 'สร้างข้อมูล'}
          </button>
          <button
            type="button"
            onClick={handleReset}
            className="flex-1 bg-gray-100 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-colors dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600"
          >
            รีเซ็ต
          </button>
        </div>
      </form>
  
      {/* Confirmation Dialog */}
      {showConfirmDialog && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-md w-full mx-4 shadow-xl">
            <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">
              {editMode ? 'ยืนยันการแก้ไขข้อมูล' : 'ยืนยันการสร้างข้อมูล'}
            </h3>
            
            {/* Dialog Content */}
            <div className="text-gray-700 dark:text-gray-300 space-y-2">
              <p className="font-medium mb-3">กรุณาตรวจสอบข้อมูลต่อไปนี้:</p>
              
              <div className="mb-4">
                <p><span className="font-medium">ชื่อสถานที่:</span> {formData?.Location || 'ไม่ระบุ'}</p>
                <p><span className="font-medium">ตำแหน่ง:</span> {formData?.address || 'ไม่ระบุ'}</p>
                <p><span className="font-medium">พิกัด:</span> {formData?.latitude}, {formData?.longitude}</p>
              </div>
  
              <div>
                <p className="font-medium mb-2">กิจกรรม:</p>
                {propActivities?.map((activity, index) => (
                  <div 
                    key={index} 
                    className="pl-4 border-l-2 border-gray-200 dark:border-gray-700 mb-2"
                  >
                    <p><span className="font-medium">วันและเวลา:</span> {activity.date || 'ไม่ระบุ'}</p>
                    <p><span className="font-medium">กิจกรรม:</span> {activity.activities || 'ไม่ระบุ'}</p>
                    <p>
                      <span className="font-medium">แหล่งอ้างอิง:</span>{' '}
                      {activity.reference ? (
                        <a 
                          href={activity.reference} 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="text-blue-600 hover:underline"
                        >
                          {activity.reference}
                        </a>
                      ) : (
                        'ไม่ระบุ'
                      )}
                    </p>
                  </div>
                ))}
              </div>
            </div>
  
            {/* Dialog Actions */}
            <div className="flex justify-end gap-4 mt-6">
              <button
                onClick={() => setShowConfirmDialog(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 transition-colors"
              >
                ยกเลิก
              </button>
              <button
                onClick={handleConfirmSubmit}
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-blue-500 dark:hover:bg-blue-400 transition-colors"
              >
                ยืนยัน
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddFormFestival;