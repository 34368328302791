//Ftestival_edit.jsx
import React, { useState, useCallback } from "react";
import { MapContainer, TileLayer, LayersControl, Marker, Popup, useMapEvents } from "react-leaflet";
import { Icon } from 'leaflet';
import "leaflet/dist/leaflet.css";
import AddFormFestival from "./AddForm_festival";
import MapMarkersfestival from "./databaserender-festival";

// Custom icon configuration
const customIcon = new Icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/512/9131/9131546.png",
    iconSize: [38, 38]
});

// Map click handler component
const MapClickHandler = ({ onLocationChange, isEnabled }) => {
    useMapEvents({
        click(e) {
            if (!isEnabled) return;
            const { lat, lng } = e.latlng;
            // เรียกฟังก์ชัน onLocationChange ด้วยพิกัดที่ได้จากการคลิก
            onLocationChange({
                latitude: lat.toFixed(6),
                longitude: lng.toFixed(6)
            });
        }
    });
    return null;
};

export default function Festival_edit() {
    const { BaseLayer } = LayersControl;
    const [formData, setFormData] = useState({
        Location: '',
        address: '',
        latitude: '',
        longitude: ''
    });
    // State management
    const [state, setState] = useState({
        isEditMode: false,
        selectedMarker: null,
        latitude: "",
        longitude: "",
        tempMarker: null,
        isSubmitting: false,
        error: null
    });

    const [activities, setActivities] = useState([
        { date: '', activities: '', reference: '' }
    ]);

    const [markerHandlers, setMarkerHandlers] = useState(null);

    // Reset form function
    const resetForm = useCallback(async () => {
        // รอให้การ fetch ข้อมูลเสร็จก่อน
        try {
            await Promise.all([
                markerHandlers?.fetchMarkers(),
                markerHandlers?.fetchactivities_markers(),
                markerHandlers?.fetchActivities()
            ]);
    
            // จากนั้นค่อย reset state
            setState(prev => ({
                ...prev,
                isEditMode: false,
                selectedMarker: null,
                latitude: "",
                longitude: "",
                tempMarker: null,
                error: null
            }));
            setActivities([{ date: '', activities: '', reference: '' }]);
        } catch (error) {
            console.error("Error refreshing data:", error);
        }
    }, [markerHandlers]);

    // Map interaction handlers
    const handleMapClick = useCallback(({ latitude, longitude }) => {
        setState(prev => ({
            ...prev,
            latitude,
            longitude,
            tempMarker: { latitude: Number(latitude), longitude: Number(longitude) },
            error: null
        }));
    }, []);

    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        setState(prev => ({
            ...prev,
            [name]: value
        }));
    }, []);

    // Activities management
    const handleAddActivity = useCallback(() => {
        setActivities(prev => [...prev, { date: '', activities: '', reference: '' }]);
    }, []);

    

    const handleActivityChange = useCallback((index, field, value) => {
        setActivities(prev => {
            const newActivities = [...prev];
            newActivities[index] = { ...newActivities[index], [field]: value };
            return newActivities;
        });
    }, []);

    // Marker editing
    const handleEdit = useCallback((marker) => {
        console.log('Edit marker data:', marker);
        
        if (!marker || !marker.Location) {
            console.error('Missing marker data:', marker);
            return;
        }
        
        // Ensure all necessary fields are populated
        setState(prev => ({
            ...prev,
            isEditMode: true,
            selectedMarker: { ...marker }, // Create a full copy of the marker
            latitude: String(marker.latitude || ''),
            longitude: String(marker.longitude || ''),
            tempMarker: null,
            error: null
        }));
    
        // Update form data directly
        setFormData({
            Location: marker.Location || '',
            address: marker.address || '',
            latitude: String(marker.latitude || ''),
            longitude: String(marker.longitude || '')
        });
    
        // Load activities
        const initialActivities = marker.activities?.map(activity => ({
            id: activity.id,
            date: activity.date || '',
            activities: activity.activities || '',
            reference: activity.reference || '',
            location_id: marker.location_id
        })) || [{ 
            date: '', 
            activities: '', 
            reference: '',
            id: undefined,
            location_id: marker.location_id 
        }];
    
        console.log('Mapped activities for edit:', initialActivities);
        setActivities(initialActivities);
    
    }, []);

  
    // In Festival_edit.jsx
    const handleFormSubmit = async (submissionData, event) => {
        if (event && typeof event.preventDefault === 'function') {
            event.preventDefault();
        }
    
        try {
            if (!submissionData) {
                alert("No data to submit");
                return;
            }
            console.log("Submission data:", submissionData);
    
            const validActivities = submissionData.activities.filter(
                (activity) => activity.date.trim() !== "" && activity.activities.trim() !== ""
            );
    
            if (!state.isEditMode) {
                // 1. สร้าง marker ก่อน
                const markerData = {
                    Location: submissionData.Location,
                    address: submissionData.address,
                    latitude: submissionData.latitude,
                    longitude: submissionData.longitude
                };
                console.log("Creating marker with data:", markerData);
                
                const locationResponse = await markerHandlers.addMarker(markerData);
                console.log("Location response:", locationResponse);
    
                // Modify this condition to be more flexible
                if (!locationResponse || 
                    (locationResponse.status && locationResponse.status !== 'success') || 
                    !locationResponse.id) {  
                    console.error("Error creating location:", locationResponse);
                    alert("เกิดข้อผิดพลาดในการสร้างสถานที่: " + 
                        (locationResponse?.message || 'ไม่สามารถสร้างสถานที่ได้'));
                    return;
                }
    
                // 2. สร้างกิจกรรมสำหรับ marker ที่สร้างใหม่
                if (validActivities.length > 0) {
                    console.log("Creating activities for location_id:", locationResponse.id);
                    const successfulActivities = [];
                    
                    for (const activity of validActivities) {
                        try {
                            if (!activity.date.trim() || !activity.activities.trim()) {
                                console.log("Skipping empty activity:", activity);
                                continue;
                            }
                
                            const activityData = {
                                location_id: locationResponse.id,
                                date: activity.date.trim(),
                                activities: activity.activities.trim(),
                                reference: activity.reference?.trim() || ""
                            };
                            console.log("Sending activity data:", activityData);
                
                            const createdActivity = await markerHandlers.createActivities(activityData);
                            console.log("Created activity response:", createdActivity);
                
                            // Modify this condition to be more flexible
                            if (createdActivity && 
                                (createdActivity.id || 
                                 (createdActivity.status && createdActivity.status === 'success'))) {
                                successfulActivities.push(createdActivity.data || createdActivity);
                            } else {
                                console.error("Invalid activity creation response:", createdActivity);
                                throw new Error('Invalid response from createActivities');
                            }
                        } catch (error) {
                            console.error("Error creating activity:", error);
                            alert(`เกิดข้อผิดพลาดในการสร้างกิจกรรม: ${error.message}`);
                        }
                    }
                
                    // ตรวจสอบว่ามีกิจกรรมที่สร้างสำเร็จหรือไม่
                    if (successfulActivities.length > 0) {
                        console.log("Successfully created activities:", successfulActivities);
                        setActivities(successfulActivities);
                    } else {
                        alert("ไม่สามารถสร้างกิจกรรมได้");
                        return;
                    }
                }
           
                alert("สร้างข้อมูลสำเร็จ");
                // await resetForm();
    
     
    
            } else {
                // Edit mode logic
                try {
                    // First, update the marker information
                    const markerUpdateData = {
                        id: state.selectedMarker.location_id,
                        location_name: submissionData.Location,
                        address: submissionData.address,
                        latitude: submissionData.latitude,
                        longitude: submissionData.longitude
                    };
            
                    // Update marker first with better error handling
                    if (markerHandlers.editMarker) {
                        const markerUpdateResponse = await markerHandlers.editMarker(markerUpdateData);
                        console.log("Marker update response:", markerUpdateResponse);
                        
                        if (!markerUpdateResponse || markerUpdateResponse.status === 'error') {
                            throw new Error(markerUpdateResponse?.message || 'Failed to update marker');
                        }
                    }
            
                    // Handle activities with better validation
                    const currentActivities = state.selectedMarker.activities || [];
                    const validActivities = submissionData.activities.filter(
                        activity => activity.date.trim() && activity.activities.trim()
                    );
            
                    // Identify activities to delete
                    const activitiesToDelete = currentActivities.filter(existingAct => 
                        !validActivities.some(newAct => 
                            (newAct.id && newAct.id === existingAct.id) || 
                            (newAct.date === existingAct.date && newAct.activities === existingAct.activities)
                        )
                    );
            
                    console.log('Activities to delete:', activitiesToDelete);
            
                    // Delete activities with better error handling
                    if (activitiesToDelete.length > 0) {
                        const deletePromises = activitiesToDelete
                            .filter(activity => activity.id)
                            .map(async activity => {
                                try {
                                    const result = await markerHandlers.deleteActivity(
                                        activity.id, 
                                        state.selectedMarker.location_id
                                    );
                                    if (!result) {
                                        console.error(`Failed to delete activity ${activity.id}`);
                                        return false;
                                    }
                                    return true;
                                } catch (error) {
                                    console.error(`Error deleting activity ${activity.id}:`, error);
                                    return false;
                                }
                            });
            
                        const deleteResults = await Promise.all(deletePromises);
                        if (!deleteResults.every(result => result)) {
                            throw new Error("ไม่สามารถลบบางกิจกรรมได้");
                        }
                    }
            
                    // Update existing activities with better error handling
                    const existingActivities = validActivities.filter(act => act.id);
                    if (existingActivities.length > 0) {
                        const updatePromises = existingActivities.map(async activity => {
                            try {
                                const result = await markerHandlers.editActivity({
                                    id: activity.id,
                                    location_id: state.selectedMarker.location_id,
                                    date: activity.date.trim(),
                                    activities: activity.activities.trim(),
                                    reference: activity.reference?.trim() || ""
                                });
            
                                if (!result || result.status === 'error') {
                                    console.error(`Failed to update activity ${activity.id}:`, result);
                                    return false;
                                }
                                return true;
                            } catch (error) {
                                console.error(`Error updating activity ${activity.id}:`, error);
                                return false;
                            }
                        });
            
                        const updateResults = await Promise.all(updatePromises);
                        if (!updateResults.every(result => result)) {
                            throw new Error("ไม่สามารถอัพเดทบางกิจกรรมได้");
                        }
                    }
            
                    // Add new activities with better error handling
                    const newActivities = validActivities.filter(act => !act.id);
                    if (newActivities.length > 0) {
                        const createPromises = newActivities.map(async activity => {
                            try {
                                const result = await markerHandlers.createActivities({
                                    location_id: state.selectedMarker.location_id,
                                    date: activity.date.trim(),
                                    activities: activity.activities.trim(),
                                    reference: activity.reference?.trim() || ""
                                });
            
                                if (!result || (result.status && result.status === 'error')) {
                                    console.error('Failed to create activity:', result);
                                    return false;
                                }
                                return true;
                            } catch (error) {
                                console.error('Error creating activity:', error);
                                return false;
                            }
                        });
            
                        const createResults = await Promise.all(createPromises);
                        if (!createResults.every(result => result)) {
                            throw new Error("ไม่สามารถเพิ่มบางกิจกรรมใหม่ได้");
                        }
                    }
            
                    // If we got here, everything succeeded
                  
                            // เพิ่มการ refresh ข้อมูลทั้งหมด
                    if (markerHandlers.fetchactivities_markers) {
                        await markerHandlers.fetchactivities_markers();
                    }
                    
                    if (markerHandlers.fetchMarkers) {
                        await markerHandlers.fetchMarkers();
                    }

                    if (markerHandlers.fetchActivities) {
                        await markerHandlers.fetchActivities();
                    }

                    // รีเซ็ตฟอร์มหลังจาก refresh ข้อมูลเสร็จ
                    // await resetForm();
                        
                } catch (error) {
                    console.error("Error in edit mode:", error);
                    alert("เกิดข้อผิดพลาดในการแก้ไขข้อมูล: " + error.message);
                    return;
                }
            }
    
            // แจ้งเตือนเมื่อสำเร็จ
            alert(state.isEditMode ? "แก้ไขข้อมูลสำเร็จ" : "สร้างข้อมูลสำเร็จ");
            
            // รีเซ็ตฟอร์มและรีเฟรชข้อมูล
            await resetForm();
            
            if (markerHandlers.fetchMarkers) {
                await markerHandlers.fetchMarkers();
            }
    
        } catch (error) {
            console.error("Error submitting form:", error);
            alert("เกิดข้อผิดพลาดที่ไม่คาดคิด: " + error.message);
        }
    };
    
    
    
    
      

    // Handle close/cancel
    const handleClose = useCallback(() => {
        if (!state.isSubmitting) {
            resetForm();
        }
    }, [state.isSubmitting, resetForm]);
    // Add this function after your function definitions but before the return.
const handleDeleteActivity = useCallback(async (activityId, locationId) => {
    console.log('Deleting activity:', { activityId, locationId }); // เพิ่มบรรทัดนี้
    if (!activityId) {
        alert('รหัสกิจกรรมไม่ถูกต้อง');
        return false;
    }

    if (!window.confirm('คุณต้องการลบกิจกรรมนี้ใช่หรือไม่?')) {
        return false;
    }

    try {
        const response = await fetch(
            'http://localhost/crud_lastest_version2/crud_lastest_version2/backend/delete_activities.php',
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY': 'Chaipong'
                },
                body: JSON.stringify({ id: activityId })
            }
        );

        const result = await response.json();

        if (result.status === 'ok') {
            // อัปเดต state ของกิจกรรมหลังจากลบสำเร็จ
            setActivities(prevActivities => prevActivities.filter(activity => activity.id !== activityId ));
            alert('ลบกิจกรรมสำเร็จ');
            return true;
        } else {
            console.error('Delete failed:', result.message);
            alert('ไม่สามารถลบกิจกรรมได้: ' + (result.message || 'เกิดข้อผิดพลาดที่ไม่ทราบสาเหตุ'));
            return false;
        }
    } catch (error) {
        console.error('Error during deletion:', error);
        alert('เกิดข้อผิดพลาดในการลบข้อมูล: ' + error.message);
        return false;
    }
}, [setActivities]);
    return (
        <div className="flex flex-col items-center justify-center p-4">
            <MapContainer
                style={{ height: "600px", width: "100%", zIndex: "30" }}
                center={[17.09483155, 99.81838832]}
                zoom={14}
                zoomControl={false}
            >
                <LayersControl position="topright">
                    <BaseLayer name="OpenStreetMap">
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        />
                    </BaseLayer>
                    <BaseLayer name="Alidade Satellite" checked>
                        <TileLayer
                            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                            attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                        />
                    </BaseLayer>
                    <MapMarkersfestival
                        onEdit={handleEdit}
                        onMarkerChange={setMarkerHandlers}
                    />
                </LayersControl>

                <MapClickHandler 
                    onLocationChange={handleMapClick}
                    isEnabled={!state.isEditMode} // ป้องกันการแก้ไขเมื่ออยู่ในโหมดแก้ไข
                />

                {state.tempMarker && (
                    <Marker
                        icon={customIcon}
                        position={[state.tempMarker.latitude, state.tempMarker.longitude]}
                    >
                        <Popup>
                            <strong>ตำแหน่งที่เลือก</strong><br />
                            {state.tempMarker.latitude}, {state.tempMarker.longitude}
                        </Popup>
                    </Marker>
                )}
            </MapContainer>

            {state.error && (
                <div className="w-full mt-4 p-4 bg-red-50 border-l-4 border-red-500 text-red-700">
                    <p>{state.error}</p>
                </div>
            )}

                <AddFormFestival
                onSubmit={handleFormSubmit}
                latitude={state.latitude}
                longitude={state.longitude}
                onInputChange={handleInputChange}
                onClose={handleClose}
                editMode={state.isEditMode}
                initialData={state.selectedMarker} // Make sure this is the full marker object
                activities={activities}
                setActivities={setActivities}
                onAddActivity={handleAddActivity}
                onActivityChange={handleActivityChange}
                isSubmitting={state.isSubmitting}
                onRemoveActivity={handleDeleteActivity}
                />
        </div>
    );
}