import React, { useState } from "react";
import { MapContainer, TileLayer, LayersControl, Marker, Popup, useMapEvents } from "react-leaflet";
import { Icon } from 'leaflet';
import "leaflet/dist/leaflet.css";
import MapMarkersdisableperson from "./databaserender-disable-person";
import AddForm from "./AddForm";

const customIcon = new Icon({
    iconUrl: "https://cdn.pixabay.com/photo/2015/05/09/02/51/handicap-759184_1280.png",
    iconSize: [38, 38]
});

const MapClickHandler = ({ onLocationChange }) => {
    useMapEvents({
        click(e) {
            const { lat, lng } = e.latlng;
            onLocationChange({
                latitude: lat.toFixed(6),
                longitude: lng.toFixed(6)
            });
        }
    });
    return null;
};

export default function Disableperson_edit() {
    const { BaseLayer } = LayersControl;

    // States
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [tempMarker, setTempMarker] = useState(null);
    const [markerHandlers, setMarkerHandlers] = useState(null);

    const handleMapClick = ({ latitude, longitude }) => {
        setLatitude(latitude);
        setLongitude(longitude);
        setTempMarker({ latitude: Number(latitude), longitude: Number(longitude) });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "latitude") setLatitude(value);
        if (name === "longitude") setLongitude(value);
    };

    const handleEdit = (marker) => {
        setIsEditMode(true);
        setSelectedMarker(marker);
        setLatitude(marker.latitude.toString());
        setLongitude(marker.longitude.toString());
        setTempMarker(null); // เพิ่มบรรทัดนี้เพื่อให้ tempMarker หายไป
    };

    const handleFormSubmit = async (formData) => {
        if (!markerHandlers) return;

        const markerData = {
            ...formData,
            latitude: latitude,
            longitude: longitude
        };

        let success = false;
        if (isEditMode) {
            success = await markerHandlers.updateMarker(markerData);
        } else {
            success = await markerHandlers.addMarker(markerData);
        }

        if (success) {
            // Reset form and states
            setTempMarker(null);
            setIsEditMode(false);
            setSelectedMarker(null);
            setLatitude("");
            setLongitude("");
        }
    };

    return (
        <div className="flex flex-col items-center justify-center p-4">
            <MapContainer
                style={{ height: "500px", width: "100%", zIndex: "30" }}
                center={[16.7619778601, 100.1865224515]}
                zoom={14}
                zoomControl={false}
            >
                <LayersControl position="topright">
                    <BaseLayer name="OpenStreetMap">
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        />
                    </BaseLayer>
                    <BaseLayer name="Alidade Satellite" checked>
                        <TileLayer
                            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                            attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                        />
                    </BaseLayer>
                    <MapMarkersdisableperson
                        onEdit={handleEdit}
                        onMarkerChange={setMarkerHandlers}
                    />
                </LayersControl>

                {!isEditMode && (
                        <MapClickHandler onLocationChange={handleMapClick} />
                    )}

                {tempMarker && (
                    <Marker
                        icon={customIcon}
                        position={[tempMarker.latitude, tempMarker.longitude]}
                    >
                        <Popup>
                            <strong>ตำแหน่งที่เลือก</strong><br />
                            {tempMarker.latitude}, {tempMarker.longitude}
                        </Popup>
                    </Marker>
                )}
            </MapContainer>

            <AddForm
                onSubmit={handleFormSubmit}
                latitude={latitude}
                longitude={longitude}
                onInputChange={handleInputChange}
                onClose={() => {
                    setTempMarker(null);
                    setIsEditMode(false);
                    setSelectedMarker(null);
                    setLatitude("");
                    setLongitude("");
                }}
                editMode={isEditMode}
                initialData={isEditMode ? selectedMarker : null}
            />
        </div>
    );
}